import React from 'react';
import * as S from './Styles.js';
import Measurements from './Measurements.js';

export default function PLCApp() {
    return (
      <S.PLCDiv>
        <S.DisclaimerDiv>
          <p className="para"><b>Disclaimer:</b> This app has been designed for research purposes and has not been approved for clinical usage.</p>
        </S.DisclaimerDiv>
        <S.IntroDiv>
          <h1>PLC WebApp</h1>
          <p className="para"><b>Instructions:</b> Enter your measurements below, and the associated standard deviation (SD) of interobserver variability for measurements in each plane. A standard deviation of approximately 3 degrees is commonly observed in the literature.</p>
        </S.IntroDiv>
        < Measurements />
      </S.PLCDiv>
    );
  }

