import React from 'react';
import {Link} from 'react-router-dom';
import * as S from './Styles.js';
import lenkeClassImg from '../images/LenkeClassification.png';
import cobbCalcImg from '../images/CobbCalc.png';

export default function Descriptions() {
    return (
        <S.DescriptionsDiv>
            <S.DescriptionsTopic>
                <S.DescriptionsItem>
                    <h1 className="title">Probabilistic Lenke Classification</h1>
                    <Link className="link" to='/plcapp'><h1 className="link">PLC WebApp</h1></Link>
                    <div className="seperator"></div>
                    <p className="para">
                        Probabilistic Lenke Classification (PLC) was developed in this study to enable orthopaedic surgeons to account for interobserver variability in Lenke classification. Each angle measured by a clinician is subject to an inherent bias. With PLC, a patient&apos;s true underlying Cobb angle is modelled as a normal distribution around the measured angle. This allows for calculation of the probability of each Lenke classification, providing an objective indicator of the level of uncertainty in classification for a given patient.<br/>Use the <Link className="link" to='/plcapp'>WebApp</Link> or find the source code in <a className="link" href="https://github.com/darraghmaguire/probabilistic-lenke-classification">this repository</a>.
                    </p>
                </S.DescriptionsItem>
                <img src={lenkeClassImg} className="img" alt="Lenke Classification Chart"/>
            </S.DescriptionsTopic>
            <S.DescriptionsTopic>
                <S.DescriptionsItem>
                    <h1 className="title">Automatic Scoliosis Assessment</h1>
                    <Link className="link" to='/cobbcalculator'><h1 className="link">Cobb Angle Calculator</h1></Link>
                    <div className="seperator"></div>
                    <p className="para">
                        Deep learning was applied to develop a system for automatic calculation of Cobb angles in spinal x-rays, aiming to improve the accuracy and repeatability of clinical measurements. Our proposed tool can locate each vertebra, define the vertebral endplates, determine the most sloped vertebrae, and calculate the PT, MT, and TL/L Cobb angles in a given x-ray.<br/>See a <Link className="link" to='/cobbcalculator'>sample of performance</Link> or find the source code in <a className="link" href="https://github.com/darraghmaguire/automatic-scoliosis-assessment">this repository</a>.
                    </p>
                </S.DescriptionsItem>
                <img src={cobbCalcImg} className="img" alt="Cobb Angle Measurement Illustration"/>
            </S.DescriptionsTopic>
        </S.DescriptionsDiv>
    );
}