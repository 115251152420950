import React from 'react';
import * as S from './Styles.js';
import samplePerformance from '../images/SamplePerformance.svg';

export default function Performance() {
    return (
        <S.Performance>
            <h1 className="title">Random Sample of Performance on Testing Dataset</h1>
            <img src={samplePerformance} className="img" alt="Sample of Algorithm Performance"/>
        </S.Performance>
    );
}