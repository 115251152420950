import styled from 'styled-components';

const OverviewDiv = styled.div`
    height: 30vh;
    @media screen and (max-width: 768px) {
        height: 50vh;
    }
    background: #bddbd3;
    color: #283E32;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .para {
        margin: 3vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;
        text-align: center;

        @media screen and (max-width: 768px) {
            margin: 3vh 10vw;
        }
    }

    .seperator {
        width: 2rem;
        height: 1px;
        background: #283E32;
    }

    .link {
        color: #1f8c66;
        font-weight: bold;
        text-decoration: none;
        &:hover {
            color: white;
        }
    }
`;

const DescriptionsDiv = styled.div`
    height: auto;
    background: white;
    color: #283E32;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 3vh 0;

    .para {
        margin: 3vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;
        text-align: center;

        @media screen and (max-width: 768px) {
            margin: 3vh 10vw;
        }
    }
`;

const DescriptionsTopic = styled.div`
    display: flex;
    flex-flow: row nowrap;
    @media screen and (max-width: 768px) {
        flex-flow: column nowrap;
    }
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    .img {
        display: block;
        width: auto;
        height: 50vh;
        margin: 3vh 3vw;
    }
`;

const DescriptionsItem = styled.div`
    display: flex;
    flex-flow: column nowrap;
    @media screen and (max-width: 768px) {
        flex-flow: column nowrap;
    }
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;

    .title {
        font-size: 3vh;
    }

    .link {
        color: #1f8c66;
        font-weight: bold;
        text-decoration: none;
        font-size: 2vh;
        &:hover {
            color: #0f98d4;
        }
    }

    .para {
        margin: 3vh 3vw;
        width: 40vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            width: auto;
            margin: 3vh 10vw;
        }
    }

    .seperator {
        width: 2rem;
        height: 1px;
        background: black;
    }
`;

const AuthorDiv = styled.div`
    height: auto;
    background: #22523f;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .heading {
        margin: 8vh 0 2vh 0;
    }

    .name {
        font-size: 3vh;
    }

    .sectionHeading {
        font-size: 3.5vh;
        margin: 5vh 0 2vh 0;
    }

    .para {
        margin: 1vh 25vw 8vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            margin: 1vh 10vw 8vh 10vw;;
        }
    }

    .seperator {
        width: 2rem;
        height: 1px;
        background: white;
    }
`;

const TeamDiv = styled.div`
    height: auto;
    background: #26826d;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .heading {
        margin: 5vh 0 2vh 0;
    }

    .name {
        font-size: 3vh;
    }

    .sectionHeading {
        font-size: 3.5vh;
        margin: 4vh 0 2vh 0;
    }

    .para {
        margin: 1vh 25vw 8vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            margin: 1vh 10vw 8vh 10vw;;
        }
    }

    .seperator {
        width: 2rem;
        height: 1px;
        background: white;
    }
`;

const TeamMembers = styled.div`
    display: flex;
    flex-flow: row nowrap;
    @media screen and (max-width: 768px) {
        flex-flow: column nowrap;
    }
    justify-content: center;
    align-items: center;
`;

const Individual = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 19vw;
    @media screen and (max-width: 768px) {
        width: auto;
    }
    justify-content: center;
    align-items: center;
    text-align: center;

    .name {
        font-size: 3vh;
    }

    .description {
        margin: 0 1vw 5vh 1vw;
        @media screen and (max-width: 768px) {
            margin: 0 10vw 3vh 10vw;
        }
        word-spacing: 2px;
        line-height: 1.5;
    }

    .seperator {
        width: 1rem;
        height: 1px;
        background: white;
    }
`;

export {
    OverviewDiv,
    DescriptionsDiv,
    DescriptionsTopic,
    DescriptionsItem,
    AuthorDiv,
    TeamDiv,
    TeamMembers,
    Individual
}