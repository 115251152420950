import React from 'react';
import * as S from './Styles.js';

export default function Intro() {
    return (
        <S.IntroDiv>
            <h1>Cobb Angle Calculator</h1>
            <div className="seperator"></div>
            <p className="para">
            The performance of our novel x-ray assessment tool is illustrated below. This fully-automatic process can be combined with the uncertainty analysis of PLC to present a robust and reliable clinical solution, improving accuracy, reliability, and workflows.
            </p>
        </S.IntroDiv>
    );
}