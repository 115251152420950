import React from 'react';
import * as S from './Styles.js';

export default function Overview() {
    return (
        <S.OverviewDiv>
            <h1>Project Overview</h1>
            <div className="seperator"></div>
            <p className="para">
                The aim of this project was to develop novel computational methods to improve clinical assessment of scoliosis. All of the developed tools are free and open-source, with complete documentation and code available from <a className="link" href="https://github.com/scoliosistools?tab=repositories">GitHub</a>.
            </p>
        </S.OverviewDiv>
    );
}
