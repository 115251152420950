import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';
import * as S from './Styles.js';

function roundOff(num) {
    return +(Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2)
}

export default class LambdaTransaction extends React.Component {

    constructor(props) {
        super(props);
        this.charts = React.createRef()
        this.state = {
            isLoaded: false,
            items: [],
        }
    }

    componentDidMount() {
        let query = Object.keys(this.props.measurements)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(this.props.measurements[k]))
             .join('&');
        let url = 'https://u514fjctj0.execute-api.eu-west-1.amazonaws.com/test/plc?' + query;
        // https://u514fjctj0.execute-api.eu-west-1.amazonaws.com/test/plc?CPT=0&CMT=0&CTL=0&BPT=0&BMT=0&BTL=0&SPT=0&SMT=0&STL=0&CSD=0.1&SSD=0.1
        fetch(url)
        .then(response => response.json())
        .then(jsonResponse => {
            console.log(jsonResponse);
            this.setState({
                isLoaded: true,
                items: jsonResponse,
            })
        }).catch(function(err) {
            console.log(err);
        }).then();
    }

    render() {
        var {isLoaded, items} = this.state;

        if (!isLoaded) {
            return <div>Loading...</div>
        }

        else {
            return (
                <S.ResultsDiv className="LambdaTransaction">
                    <h2 className="resultsHeading">Probability of each Category</h2>
                    <Chart 
                        width= '100%'
                        height={200}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                        ['Category', 'Probability', { role: 'style'}],
                        ['1', roundOff(items.CT1), '#04a2c2'],
                        ['2', roundOff(items.CT2), '#04a2c2'],
                        ['3', roundOff(items.CT3), '#04a2c2'],
                        ['4', roundOff(items.CT4), '#04a2c2'],
                        ['5', roundOff(items.CT5), '#04a2c2'],
                        ['6', roundOff(items.CT6), '#04a2c2'],
                        ]}
                        options={{
                        hAxis: {
                        },
                        vAxis: {
                            title: 'Curve Type',
                            minValue: 0,
                            maxValue: 1,
                        },
                        legend: { position: 'none' },
                        }}/>
                    <Chart 
                        width= '100%'
                        height={200}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                        ['Category', 'Probability', { role: 'style'}],
                        ['-', roundOff(items.STMNeg), '#04a2c2'],
                        ['N', roundOff(items.STMNorm), '#04a2c2'],
                        ['+', roundOff(items.STMPos), '#04a2c2'],
                        ]}
                        options={{
                        chartArea: { width: '30%' },
                        hAxis: {
                        },
                        vAxis: {
                            title: 'Sagittal Thoracic Modifier',
                            minValue: 0,
                            maxValue: 1,
                        },
                        legend: { position: 'none' },
                        }}/>

                </S.ResultsDiv>
            )
        }
    }
}

LambdaTransaction.propTypes = {
    measurements: PropTypes.object
  };
