import React from 'react';
import Intro from './Intro.js';
import Process from './Process.js';
import Performance from './Performance.js';

export default function CobbCalculator() {
    return (
      <div>
        <Intro />
        <Process />
        <Performance />
      </div>
    );
  }
