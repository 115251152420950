import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Nav from './Nav/Nav.js';
import Home from './Home/Home.js';
import PLCApp from './PLCApp/PLCApp.js';
import CobbCalculator from './CobbCalculator/CobbCalculator.js';

export default function App() {
    return (
        <Router>
            <div className="App">
                <Nav />
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/plcapp" component={PLCApp} />
                  <Route path="/cobbcalculator" component={CobbCalculator} />
                </Switch>
            </div>
        </Router>
    );
}
