import React from 'react';
import * as S from './Styles.js';

export default function Author() {
    return (
        <S.AuthorDiv>
            <h1 className="heading">About the Author</h1>
            <div className="seperator"></div>
            <h1 className="name">Darragh Maguire</h1>
            <p className="para">Studying for an ME in Biomedical Engineering at University College Dublin (UCD), I developed a keen interest in deep learning, data analytics, and software engineering. Having developed a skillset in medical image analysis, I proposed and implemented the Scoliosis Tools project for my thesis. The proposed tools have been met with great enthusiasm from clinicians and the thesis project achieved a distinction.</p>
        </S.AuthorDiv>
    );
}