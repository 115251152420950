import React from 'react';
import {Link} from 'react-router-dom';
import * as S from './Styles.js';
import mainLogo from '../logo.svg';

export default function NavBar(props) {
  return (
    <S.NavDiv>
        <S.NavButton 
            displayMenu = { props.displayMenu }
            onClick = { props.toggleMenu }>
            <img className="logo" src={mainLogo} alt="logo"/>
        </S.NavButton>
        <div className="seperator"></div>
        <Link className="link" to='/' onClick = { props.hideMenu }>
            <h1 className="logo-text">Scoliosis Tools</h1>
        </Link>
        <ul className="nav-links">
            <Link className="link-focus" to='/plcapp' onClick = { props.hideMenu }>
                <li>PLC WebApp</li>
            </Link>
            <Link className="link-focus" to='/cobbcalculator' onClick = { props.hideMenu }>
                <li>Cobb Angle Calculator</li>
            </Link>
        </ul>
    </S.NavDiv>
  );
}
