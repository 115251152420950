import React from 'react';
import * as S from './Styles.js';
import LambdaTransaction from './LambdaTransaction.js';

export default class Measurements extends React.Component {
    constructor(props) {
      super(props);
      this.resultsRef = React.createRef();
      this.state = {
        coronalPT: 0.00,
        coronalMT: 0.00,
        coronalTL: 0.00,
        bendPT: 0.00,
        bendMT: 0.00,
        bendTL: 0.00,
        sagittalPT: 0.00,
        sagittalMT: 0.00,
        sagittalTL: 0.00,
        coronalSD: 3.00,
        sagittalSD: 3.00,
        jsonParams: {
          "CPT": "0",
          "CMT": "0",
          "CTL": "0",
          "BPT": "0",
          "BMT": "0",
          "BTL": "0",
          "SPT": "0",
          "SMT": "0",
          "STL": "0",
          "CSD": "3",
          "SSD": "3"
        },
        isSubmitted: false
      };
  
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;

      this.setState({
        [name]: value
      });

      this.setState({isSubmitted: false});
    }
  
    handleSubmit(event) {
      event.preventDefault();
      
      let jsonParamsCopy = {
        "CPT": this.state.coronalPT.toString(),
        "CMT": this.state.coronalMT.toString(),
        "CTL": this.state.coronalTL.toString(),
        "BPT": this.state.bendPT.toString(),
        "BMT": this.state.bendMT.toString(),
        "BTL": this.state.bendTL.toString(),
        "SPT": this.state.sagittalPT.toString(),
        "SMT": this.state.sagittalMT.toString(),
        "STL": this.state.sagittalTL.toString(),
        "CSD": this.state.coronalSD.toString(),
        "SSD": this.state.sagittalSD.toString()
      };

      this.setState({
        jsonParams: jsonParamsCopy
      });

      this.setState({isSubmitted: true});

      this.resultsRef.current.scrollIntoView({behavior: "smooth"});
    }

    setForm() {
      this.setState({isSubmitted: false});
    }
  
    render() {
      return (
        <S.FormDiv>
          <form onSubmit={this.handleSubmit}>
            <label>
              Proximal Thoracic Cobb:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="coronalPT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.coronalPT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              Main Thoracic Cobb:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="coronalMT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.coronalMT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              Thoracolumbar/lumbar Cobb:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="coronalTL"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.coronalTL}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <br />
            <label>
              Proximal Thoracic Bend:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="bendPT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.bendPT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              Main Thoracic Bend:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="bendMT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.bendMT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              Thoracolumbar/lumbar Bend:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="bendTL"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.bendTL}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <br />
            <label>
              T2-T5 Kyphosis:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="sagittalPT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.sagittalPT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              T5-T12 Kyphosis:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="sagittalMT"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.sagittalMT}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              T10-L2 Kyphosis:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="sagittalTL"
                type="number"
                min="0"
                max="180"
                step="0.01"
                value={this.state.sagittalTL}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <br />
            <label>
              Coronal Interobserver SD:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="coronalSD"
                type="number"
                min="0.01"
                max="180"
                step="0.01"
                value={this.state.coronalSD}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <label>
              Sagittal Interobserver SD:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <S.FormInput
                name="sagittalSD"
                type="number"
                min="0.01"
                max="180"
                step="0.01"
                value={this.state.sagittalSD}
                onChange={this.handleInputChange}
                required />
            </label>
            <br />
            <br />
            <S.FormInputButton type="submit" value="Enter" ref={this.resultsRef}/>
          </form>
          {this.state.isSubmitted && <LambdaTransaction measurements={this.state.jsonParams} />}
        </S.FormDiv>
      );
    }
  }
