import styled from 'styled-components';

const PLCDiv = styled.div`
    background: #04a2c2;
    color: white;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 85vh;

    @media screen and (max-width: 1040px) {
        min-height: 235vh;
    }

    .para {
        margin: 0 15vw 0 15vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            margin: 0 10vw 0 10vw;
        }
    }
`;

const DisclaimerDiv = styled.div`
    background: #bddbd3;
    color: #283E32;
    padding: 1vh 0 1vh 0;
    height: 100%;
    
    .para {
        margin: 0 5vw 0 5vw;
        word-spacing: 2px;
        line-height: 1.5;
    }
`;
const IntroDiv = styled.div`
    background: #04a2c2;
    color: white;
    /* padding: 1vh 0 1vh 0; */
    height: 100%;
    
    .para {
        margin: 0 15vw 3vh 15vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            margin: 0 5vw 10vh 5vw;;
        }

        @media screen and (max-width: 1040px) {
            margin: 0 15vw 10vh 15vw;
        }
    }
`;

const FormDiv = styled.div`
    background: #04a2c2;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    height: 100%;
    margin: 0 15vw 0 15vw;
    word-spacing: 2px;
    line-height: 1.5;
    
    /* margin: 0 30vw 0 30vw; */
    @media screen and (max-width: 1040px) {
        margin: 0 1vw 15vh 1vw;
        flex-flow: column nowrap;
    }
    
    @media screen and (max-width: 500px) {
        font-size: 3.7vw;
    }

    .field {
        border: none;
        background: #9ad3f5;
    }
`;

const FormInput = styled.input`
    border: none;
    background: #69CCBD;
    color: white;
    border-radius: 3px;
    align-items: center;
    text-align: center;

    &:focus {
        outline-color: white;
    }
`;

const FormInputButton = styled.input`
    border: none;
    background: #69CCBD;
    color: white;
    border-radius: 3px;
    align-items: center;
    text-align: right;
    padding: 0.5rem 2rem;

    @media screen and (max-width: 1040px) {
        margin: 0 0 15vh 0;
    }

    &:focus {
        outline-width: 1px;
        outline-color: white;
    }
`;

const ResultsDiv = styled.div`
    background: white;
    color: #283E32;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    height: 100%;
    word-spacing: 2px;
    line-height: 1.5;
    border-radius: 10px;
    border:3px solid white;

    @media screen and (max-width: 500px) {
        font-size: 3.7vw;
    }

    @media screen and (max-width: 1040px) {
        margin: 0 3vw 15vh 3vw;
    }

    .resultsHeading{
        margin: 3vh 3vw 0 3vw;
    }

    .field {
        border: none;
        background: #9ad3f5;
    }

    .items {
        list-style: none;
        text-decoration: none;
        padding: 0;
        list-style-type: none;
        text-align: center;
        font-weight: bold;
    }
`;

export {
    PLCDiv,
    DisclaimerDiv,
    IntroDiv,
    FormDiv,
    FormInput,
    FormInputButton,
    ResultsDiv
}