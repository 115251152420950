import React from 'react';
import {Link} from 'react-router-dom';
import * as S from './Styles.js';

export default function Menu(props) {
  return (
    <S.Menu displayMenu = { props.displayMenu }>
      <ul className="menu-links">
          <Link className="menu-link" to='/' onClick = { props.toggleMenu }>
              <li>Home</li>
          </Link>
          <Link className="menu-link" to='/plcapp' onClick = { props.toggleMenu }>
              <li>PLC WebApp</li>
          </Link>
          <Link className="menu-link" to='/cobbcalculator' onClick = { props.toggleMenu }>
              <li>Cobb Angle Calculator</li>
          </Link>
      </ul>
    </S.Menu>
  );
}
