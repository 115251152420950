import styled from 'styled-components';

const NavDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;

    background: #69CCBD;
    color: white;

    overflow-x: hidden;

    height: 15vh;
    
    .logo {
        display: block;
        width: 100%;
        max-width: 5vh;
        max-height: 5vh;
    }

    .logo-text {
      /* font-family: Impact; */
      padding: 0 2vh;
      color: white;
    }

    .nav-links {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;

        width: 35vw;

        list-style: none;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .link {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: 10vh;

        color: white;
        font-size: 2.5vh;
        text-decoration: none;
        align-items: center;
    }
    .link-focus {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: 10vh;

        color: white;
        font-size: 2.5vh;
        text-decoration: none;
        align-items: center;

        padding: 0 2vh;
        border-radius: 10px;

        &:hover {
            background: rgba(0,0,0,0.1);
            outline: none;
        }
    }

    .seperator {
        height: 70%;
        width: 1px;
        background: white;
    }
`;

const NavButton = styled.button`
  background: transparent;
  /* height: 10vh;
  width: 10vh; */
  border: none;

  align-items: center;

  padding: 2vh 3vh;
  border-radius: 10px;
  /* border-width: 1px;
  border-color: white; */

  &:focus {
    outline: none;
    background: rgba(0,0,0,0.2);
  }
  &:hover {
    background: rgba(0,0,0,0.2);
  }

`;

const Menu = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    background: #dff2e4;
    color: #2a5443;

    height: 85vh;
    width: 100%;


    @media screen and (max-width: 768px) {
        width: 100%;
    }

    &:focus {
        outline: none;
    }

    position: absolute;

    transition: transform;
    @media screen and (max-width: 768px) {
        transition: transform 0.5s ease-in-out;
    }
    transform: translateX(${ props => props.displayMenu ? ("0%") : ("-100%") });

    .menu-links {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        padding: 0;
        list-style: none;
    }

    .menu-link {
        color: #2a5443;
        font-size: 3vh;
        text-decoration: none;
        align-items: center;
        margin: 0 0 10vh 0;

        &:hover {
            color: white;
        }
    }
`;

export {
    NavDiv,
    NavButton,
    Menu
}