import React from 'react';
import * as S from './Styles.js';
import inputXray from '../images/InputXray.png';
import vertebralSegmentation from '../images/VertebralSegmentation.png';
import measuredAngles from '../images/MeasuredAngles.png';

export default function Process() {
    return (
        <S.ProcessDiv>
            <S.ProcessItem>
                <img src={inputXray} className="img" alt="Input Xray"/>
                <h1 className="title">Input X-ray</h1>
                <div className="seperator"></div>
                <p className="para">
                The assessment algorithms were developed to interpret anterior-posterior standing x-rays. However, this analysis can be easily extended to incorporate sagittal and bending x-rays - given more training data.
                </p>
            </S.ProcessItem>
            <S.ProcessItem>
                <img src={vertebralSegmentation} className="img" alt="Vertebral Segmentation"/>
                <h1 className="title">Vertebral Segmentation</h1>
                <div className="seperator"></div>
                <p className="para">
                Using state-of-the-art deep learning methods, we have successfully trained a model to accurately and reliably locate each vertebra in a given x-ray.
                </p>
            </S.ProcessItem>
            <S.ProcessItem>
                <img src={measuredAngles} className="img" alt="Measured Angles"/>
                <h1 className="title">Cobb Angle Measurement</h1>
                <div className="seperator"></div>
                <p className="para">
                We have proposed novel methods to determine the slopes of each endplate of the segmented vertebrae. This allows for rapid and robust automatic measurement of the PT, MT, and TL/L Cobb angles.
                </p>
            </S.ProcessItem>
        </S.ProcessDiv>
    );
}