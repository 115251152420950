import React from 'react';
import * as S from './Styles.js';

export default function Team() {
    return (
        <S.TeamDiv>
            <h1 className="heading">Acknowledgements</h1>
            <div className="seperator"></div>
            <p className="para">
            Project Supervisors, Dr Kathleen Curran, and Dr John Healy, provided continued support, direction, and insight. Invaluable guidance was also offered generously by many clinicians, informing this research, and ensuring its clinical feasibility.
            </p>
            <h1 className="sectionHeading">Academic</h1>
            <div className="seperator"></div>
            <S.TeamMembers>
                <S.Individual>
                    <h1 className="name">Dr Kathleen Curran</h1>
                    <p className="description">Lecturer/Assistant Professor, Diagnostic Imaging School of Medicine and UCD Centre for Biomedical Engineering</p>
                </S.Individual>
                <S.Individual>
                    <h1 className="name">Dr John Healy</h1>
                    <p className="description">Lecturer/Assistant Professor, UCD School of Electrical and Electronic Engineering</p>
                </S.Individual>
            </S.TeamMembers>
            <h1 className="sectionHeading">Clinical</h1>
            <div className="seperator"></div>
            <S.TeamMembers>
                <S.Individual>
                    <h1 className="name">Mr Michael Dodds</h1>
                    <p className="description">Consultant Orthopaedic Surgeon</p>
                </S.Individual>
                <S.Individual>
                    <h1 className="name">Mr Connor Green</h1>
                    <p className="description">Consultant Orthopaedic Surgeon</p>
                </S.Individual>
                <S.Individual>
                    <h1 className="name">Prof Eoin Kavanagh</h1>
                    <p className="description">Consultant Radiologist</p>
                </S.Individual>
                <S.Individual>
                    <h1 className="name">Dr Rosanne-Sara Lynham</h1>
                    <p className="description">Paediatric Orthopaedic Registrar</p>
                </S.Individual>
                <S.Individual>
                    <h1 className="name">Dr Ivan Welaratne</h1>
                    <p className="description">Specialist Registrar in Radiology</p>
                </S.Individual>
            </S.TeamMembers>
        </S.TeamDiv>
    );
}