import React from 'react';
import NavBar from './NavBar.js';
import Menu from './Menu.js';

export default class Nav extends React.Component {

  state = {
    displayMenu: false
  }

  toggleMenu = () => {
    this.setState( prevState => {
      return { displayMenu: !prevState.displayMenu }
    })
  }

  hideMenu = () => {
    this.setState( prevState => {
      return { displayMenu: false }
    })
  }

  render() {
    return (
      <div>
        <NavBar
          displayMenu = { this.state.displayMenu }
          toggleMenu = { this.toggleMenu }
          hideMenu = { this.hideMenu }/>
        <Menu 
          displayMenu = { this.state.displayMenu }
          toggleMenu = { this.toggleMenu }/>
      </div>
    );
  }
}
