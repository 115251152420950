import styled from 'styled-components';

const IntroDiv = styled.div`
    height: 35vh;
    @media screen and (max-width: 768px) {
        height: 50vh;
    }
    background: #bddbd3;
    color: #283E32;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .para {
        margin: 3vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;
        text-align: center;

        @media screen and (max-width: 768px) {
            margin: 3vh 10vw;
        }
    }

    .seperator {
        width: 2rem;
        height: 1px;
        background: #283E32;
    }
`;

const ProcessDiv = styled.div`
    display: flex;
    flex-flow: row nowrap;
    @media screen and (max-width: 768px) {
        flex-flow: column nowrap;
    }
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background: #eaedd5;

    .para {
        margin: 1vh 25vw 3vh 25vw;
        word-spacing: 2px;
        line-height: 1.5;
        text-align: center;

        @media screen and (max-width: 768px) {
            margin: 3vh 10vw;
        }
    }

    .img {
        display: block;
        width: auto;
        height: 50vh;
        margin: 3vh 3vw;
    }
`;

const ProcessItem = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    color: #283E32;

    .title {
        font-size: 3vh;
    }

    .seperator {
        width: 1rem;
        height: 1px;
        background: #283E32;
    }

    .para {
        margin: 1vh 3vw 3vh 3vw;
        word-spacing: 2px;
        line-height: 1.5;

        @media screen and (max-width: 768px) {
            width: auto;
            margin: 3vh 10vw;
        }
    }
`;

const Performance = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
        color: #283E32;
    }

    .img {
        display: block;
        width: auto;
        height: 80vh;
        margin: 3vh 3vw 5vh 4vw;

        @media screen and (max-width: 768px) {
            width: 95vw;
            height: auto;
            margin: 3vh 3vw 5vh 4vw;
        }
    }
`;

export {
    IntroDiv,
    ProcessDiv,
    ProcessItem,
    Performance
}
