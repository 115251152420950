import React from 'react';
import Overview from './Overview.js';
import Descriptions from './Descriptions.js';
import Author from './Author.js';
import Team from './Team.js';

export default function Home() {
    return (
      <div>
        <Overview />
        <Descriptions />
        <Author />
        <Team />
      </div>
    );
  }
